<template>
<div>

  <el-container id="countdown">
    <countdown :end-time="saleStartTime">
      <template v-slot:process="{ timeObj }">
        <template v-if="isWhitelist">Whitelist minting now. </template>
        Public minting opens in <span><template v-if="timeObj.d > 0">{{ timeObj.d }} days, </template>{{ timeObj.h }} hours, {{ timeObj.m }} minutes, {{ timeObj.s }} seconds</span>
      </template>
      <template v-slot:finish>
        <div v-if="numMinted < maxMint" style="width: 100%">
          <span>Public minting is open!</span>
        </div>
        <div v-else style="width: 100%">
          <span>
            <span>WE ARE SOLD OUT! THANK YOU!</span>
            <a href="https://objkt.com/collection/cybergeckogang">View collection on Objkt.com<i class="far fa-external-link"></i></a>
          </span>
        </div>
      </template>
    </countdown>
  </el-container>

  <el-container id="app">

    <div class="hidden-xs-only">
      <img src="./assets/logo.png" class="logo" />
      <div class="links">
        <el-link @click="changeWallet" :underline="false"><img src="./assets/button-sync.png" style="height: 72px;"/></el-link>
        <el-link :href="discordUrl" :underline="false"><img src="./assets/button-discord.png" style="height: 72px;"/></el-link>
        <el-link :href="twitterUrl" :underline="false"><img src="./assets/button-twitter.png" style="height: 72px;"/></el-link>
      </div>
    </div>

    <el-main>

      <el-row class="hidden-sm-and-up mobile">
        <el-col :span="24">
          <img src="./assets/logo.png" style="width: 100%" />
        </el-col>
      </el-row>
      <el-row :gutter="20" class="hidden-sm-and-up mobile" style="margin-top: 25px;">
        <el-col :span="8">
          <el-link :href="discordUrl" :underline="false"><img src="./assets/button-discord.png" style="width: 100%"/></el-link>
        </el-col>
        <el-col :span="8">
          <el-link :href="twitterUrl" :underline="false"><img src="./assets/button-twitter.png" style="width: 100%"/></el-link>
        </el-col>
        <el-col :span="8">
          <el-link @click="changeWallet" :underline="false"><img src="./assets/button-sync.png" style="width: 100%"/></el-link>
        </el-col>
      </el-row>

      <el-row class="hero hero-mint" style="margin: 0 auto; margin-top: 275px;">
        <el-col :xs="24" :sm="12" style="text-align: center;">
          <img src="./assets/button-pet-gecko.png" style="height: 100px;" />
          <div v-if="isWhitelist" class="mint-buttons">
            <el-link @click="mint(1)" :underline="false"><img src="./assets/button-pet-1.png" style="height: 72px;"/></el-link><br/>
            <el-link @click="mint(2)" :underline="false"><img src="./assets/button-pet-2.png" style="height: 72px;"/></el-link><br/>
            <el-link @click="mint(3)" :underline="false"><img src="./assets/button-pet-3.png" style="height: 72px;"/></el-link>
          </div>
          <el-row v-else class="mint-buttons">
            <el-col :span="12">
              <el-link @click="mint(1)" :underline="false"><img src="./assets/button-pet-1.png" style="height: 72px;"/></el-link><br/>
              <el-link @click="mint(2)" :underline="false"><img src="./assets/button-pet-2.png" style="height: 72px;"/></el-link><br/>
              <el-link @click="mint(3)" :underline="false"><img src="./assets/button-pet-3.png" style="height: 72px;"/></el-link>
            </el-col>
            <el-col :span="12">
              <el-link @click="mint(5)" :underline="false"><img src="./assets/button-pet-5.png" style="height: 72px;"/></el-link><br/>
              <el-link @click="mint(7)" :underline="false"><img src="./assets/button-pet-7.png" style="height: 72px;"/></el-link><br/>
              <el-link @click="mint(10)" :underline="false"><img src="./assets/button-pet-10.png" style="height: 72px;"/></el-link><br/>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" style="position: relative; text-align: center;">
          <img src="./assets/hatching-695x1000.gif" style="height: 400px; border: 5px solid #000;" />
        </el-col>
      </el-row>

      <el-row class="hero" style="margin: 20px auto; width: 85%;">
        <el-col :xs="24">
          <div class="mint" v-if="isMinting && numMinted < maxMint" v-loading="mintOpPending" element-loading-background="rgba(0, 0, 0, 0.6)" style="padding: 12px;">
            <el-progress :show-text="false" :stroke-width="32" :percentage="parseInt((numMinted / maxMint) * 100)" style="margin-top: 15px; margin-bottom: 8px"></el-progress>
            {{numMinted}} / {{maxMint}} minted
          </div>
          <div class="mint" v-else-if="isMinting">
            <H2>SOLD OUT</H2>
            <el-progress :show-text="false" :stroke-width="44" :percentage="100" style="margin-top: 15px; margin-bottom: 8px"></el-progress>
            <p style="margin-top: 0; margin-bottom: 8px;">{{numMinted}} / {{maxMint}} minted</p>
            <a href="https://objkt.com/collection/cybergeckogang">View collection on Objkt.com<i class="far fa-external-link"></i></a>
          </div>
          <div class="mint" v-else>
            <H2>Minting soon!</H2>
          </div>
        </el-col>
      </el-row>

<div class="faq">
  <el-row :gutter="30" type="flex" align="bottom">
    <el-col :xs="24">
      <h1 style="margin: 0;">Tell me more!</h1>
    </el-col>
  </el-row>
  <el-row :gutter="30" style="margin-top: 50px;">
    <el-col :xs="24" :md="12">
      <h2>Geckos are generative art. What's that?</h2>
      <p>This is when the artist intentionally includes randomness as part of the creative process. It creates a sort of lottery or sale, where neither the collector nor the artist knows the exact end result. Make the match even more exciting! With NFTs, most artists will ensure each minted piece is totally unique.</p>
    </el-col>
    <el-col :xs="24" :md="12">
      <h2>How will you ensure fair distribution?</h2>
      <p>The sale includes a provenance hash. This is a SHA-256 hash of an IPFS directory that refers to a fingerprint of the tokens and the art. It means anyone can verify that we didn’t change tokens after minting them. Also, when the sale starts, the id of each mint is randomized.</p>
    </el-col>
  </el-row>
  <el-row :gutter="30" style="margin-top: 50px;">
    <el-col :xs="24" :md="12">
      <h2>What is the mint price?</h2>
      <p>Mint price is set at <strong>10 XTZ</strong></p>
    </el-col>
    <el-col :xs="24" :md="12">
      <h2>How many can I mint?</h2>
      <p>Whitelisted wallets will be allowed to mint up to <strong>3 Geckos</strong> before the public sale. All wallets are capped at minting a total of <strong>10 Geckos</strong>.</p>
    </el-col>
  </el-row>
  <el-row :gutter="30" style="margin-top: 50px;">
    <el-col :xs="24" :md="12">
      <h2>When can I mint?</h2>
      <p>Whitelist minting opens November 19, 2021 at 19:00 UTC and runs for 18 hours. Public minting will commence shortly after, on November 20, 2021 at 15:00 UTC.</p>
    </el-col>
    <el-col :xs="24" :md="12">
      <h2>I minted my Gecko. Now what?</h2>
      <p>Once you have minted your Geckos, the tokens will be visible in your wallet as well as your objkt.com collection page. At first you will only see a placeholder and all the unique Gecko traits will be hidden. Once the sale ends, the actual Gecko with all its traits and unique markers will be revealed to you. This should approximately 24 hours after the sale concludes.</p>
    </el-col>
  </el-row>
  <el-row :gutter="30" style="margin-top: 50px;">
    <el-col :xs="24" :md="12">
      <h2>Where can a trade my Geckos?</h2>
      <p>The Gecko FA2 tokens will have their own collection on objkt.com and can be traded there.</p>
    </el-col>
    <el-col :xs="24" :md="12">
      <h2>Provenance &amp; Contracts</h2>
      <p v-if="crowdSaleContract">Crowdsale Contract:<br/><a :href="`https://better-call.dev/mainnet/${crowdSaleContract}/operations`">{{ crowdSaleContract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
      <p v-else>Crowdsale Contract: <strong>Coming Soon!</strong></p>
      <p v-if="fa2Contract">Gecko NFT Contract:<br/><a :href="`https://better-call.dev/mainnet/${fa2Contract}/operations`">{{ fa2Contract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
      <p v-else>Gecko NFT Contract: <strong>Coming Soon!</strong></p>
      <p v-if="provenanceHash">Provenance Hash:<br/><strong>{{ provenanceHash }}</strong></p>
      <p v-else>Provenance Hash: <strong>Coming Soon!</strong></p>
    </el-col>
  </el-row>
</div>

    </el-main>
  </el-container>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from './utils/tezos';
import tzkt from './utils/tzkt';

export default {
  name: 'app',
  data() {
    return {
      discordUrl: "https://discord.gg/cybergeckogang",
      twitterUrl: "https://twitter.com/CyberGeckoGang",
      crowdSaleContract: process.env.VUE_APP_CROWDSALE_CONTRACT,
      fa2Contract: process.env.VUE_APP_FA2_CONTRACT,
      provenanceHash: process.env.VUE_APP_PROVENANCE_HASH,
      whitelistStartTime: new Date('2021-11-19T19:00:00Z'),
      whitelistEndTime: new Date('2021-11-20T13:00:00Z'),
      saleStartTime: new Date('2021-11-20T15:00:00Z'),
      numMinted: 0,
      maxMint: 2222,
      mintOpPending: false,
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    isWhitelist: function() {
      const now = new Date();
      return (now >= this.whitelistStartTime && now < this.whitelistEndTime);
    },

    isMinting: function() {
      return this.isWhitelist || (new Date() >= this.saleStartTime);
    }
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    async updateMinted() {
      const storage = await tzkt.getContractStorage(this.crowdSaleContract);
      this.numMinted = parseInt(storage.data.sale.soldAmount) + 70;
      if (this.numMinted < this.maxMint) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint(mintNum) {
      const amount = parseInt(mintNum * 10000000);
      if (amount < 1) {
          return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(mintNum)
          .send({
            amount: amount,
            mutez: true,
            fee: 55000,
            gasLimit: 250000,
            storageLimit: 3000
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  }
}
</script>

<style scss>

html,
body {
  margin: 0;
  height: 100%;
  background: #081c23;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: #fff;
}

#app, .discord-link {
  max-width: 1280px;
  margin: 0 auto;
}

#app {
  position: relative;
  margin-top: 75px;
}

#app .logo {
  position: absolute;
  top: -15px;
  left: 0px;
  width: 650px;
}

#app .links {
  position: absolute;
  top: 0px;
  right: 0px;
}

#app .links a {
  display: block;
  margin-bottom: 18px;
}

#app .links a:hover img,
.mint-buttons a:hover img {
  filter: drop-shadow(2px 2px 10px rgba(255, 255, 255, 0.50));
}

.mint-buttons a {
  margin-top: 18px;
}


#app .el-button.is-plain {
  background: transparent;
  border: 1px solid #fff;
  border-color: #fff;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  padding: 10px 54px;
  font-size: 16px;
  border-radius: 5px;
}
#app .el-button.is-plain:hover {
  border-color: #F5831E;
  color: #F5831E;
}

#app .el-select {
  width: 80px;
  margin-right: 15px;
}

#app .el-progress-bar__outer {
  background: #000;
  border-radius: 0;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to right, #149f4c, #d5dd32);
}

#app .el-progress-bar__inner {
  border-radius: 0;
  background-color: #fff;
}

.el-loading-mask {
  border-radius: 12px;
}

.discord-link {
  max-width: 976px;
  margin-top: 75px;
}

.discord-link i {
    font-size: 96px;
}

.discord-link h1 {
    margin-top: 32px;
}

.discord-link h1 a, .discord-link h1 a:visited {
    text-decoration: none;
    color: #fff;
}
.discord-link h1 a:hover {
    text-decoration: underline;
}

#footer {
  height: 556px;
}

#footer .el-footer {
  padding-top: 120px;
  height: 100%;
}

#countdown {
  background: #17a24b;
  color: #fff;
  padding: 12px;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

#countdown span {
  display: block;
  width: 100%;
}

#countdown span span {
  font-family: 'Nova Mono', sans-serif;
  font-size: 32px;
  margin: 18px 0 12px 0;
}

#countdown a {
  color: #fff;
  text-decoration: none;
}
#countdown a:hover {
  text-decoration: underline;
}
#countdown a i {
  margin-left: 12px;
}

.el-header {
  position: relative;
}

.photo {
  width: 175px;
  border: 4px solid rgb(0, 0, 0);
  position: absolute;
  top: 125px;
  left: 175px;
  box-shadow: -4px 1px 10px 2px rgba(0, 0, 0, 0.25);
}


.el-header .links a,
.el-header .links a:visited {
  display: block;
  margin-top: 6px;
  float: left;
  font-size: 28px;
  color: #fff;
  margin-right: 24px;
  line-height: 32px;
}
.el-header .links a:hover {
  color: #43abff;
}

h1 {
  font-family: 'Nova Mono', sans-serif;
  font-size: 76px;
  margin-bottom: 32px;
  line-height: 66px;
  text-transform: uppercase;
}


p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #ccc;
  margin-bottom: 24px;
}

ul li {
  font-size: 16px;
  line-height: 25px;
  color: #ccc;
}

ul li strong {
    color: #fff;
}

p a {
  color: #fff;
}

.faq {
  margin: 150px 200px;
  margin-bottom: 0;
}
.faq h2 {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 40px;
}
.faq p {
  font-size: 14px;
}
.faq strong {
  color: #fff;
}

.mint {
  text-align: center;
}
.mint .el-button, .faq .el-button {
  background-color: #F5831E;
  border-color: #F5831E;
  color: #fff;
  font-weight: 500;
  padding: 12px 72px;
  font-size: 16px;
  border-radius: 5px;
}

.mint a {
  color: #fff;
  font-size: 14px;
}

.mint a i {
  margin-left: 8px;
}

.team {
  background: linear-gradient(0deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.25) 100%);
  border-radius: 10px;
}

.team h2 {
    font-size: 18px;
}

.el-avatar {
    background: transparent;
}

@media only screen and (max-width: 767px) {
  #app {
    margin-top: 25px;
  }
  #app .hero-mint {
    margin-top: 35px !important;
  }
  #app .hero-mint .el-col:first-child {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 991px) {
  #app {
    margin-left: 25px;
    margin-right: 25px;
  }
  #app .logo {
    width: 500px;
    left: -15px;
  }
  .mint-buttons img {
    width: 85% !important;
    height: auto !important;
  }
  .faq {
    margin: 50px 0px;
  }
}

</style>
